import { ChangeEvent, useEffect, useState } from 'react';
import { Text } from '../../Atoms/Text';
import { FormState, useForm } from 'react-hook-form';
import { InventoryAsset } from '../../../types';
import { fetchSharedAccountCustomizations } from '../../../store/SharedAccountCustomizations';
import { emailRegex } from '../../../utils/helpers';
import AdditionalRecipients from '../../Pages/UserSettings/AdditionalRecipients';
import { TextSpan } from '../../Atoms/Text';
import { FormControl, FormControlLabel, FormLabel, OutlinedInput, Radio, RadioGroup, Select } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { SELECT_INPUT_STYLE } from '../../../utils/styleHelpers';

export interface FormData {
  Status: string;
  AccountId: string;
  ContactId: string;
  isServiceDown: string;
  serviceSubject: string;
  Description: string;
  issueType: string;
  Reason: string;
  Priority: string;
  Subject: string;
  OrderProductId: string;
  AdditionalRecipients: string;
  supplierTicketNumber: string;
}

const validateDescription = (value: string) => {
  if (value == 'required') return 'Please enter a detailed description of your issue';
  if (value == 'maxLength') return 'Character count is limited to 2,000';
  return true;
};

const infoText = () => {
  return (
    <Text
      size="sm14"
      className="text-center mt-3 mx-2"
      color="grey5">
      You can upload relevant attachments after you have created your ticket.
    </Text>
  );
};

const addressSelect = (service: InventoryAsset) => {
  const value = service.addressInfo.number ? `${service.addressInfo.number} - ${service.address}` : service.address;
  return (
    <Select
      input={<OutlinedInput />}
      IconComponent={() => <ArrowDropDown style={{ display: 'none' }} />}
      native
      size="small"
      disabled>
      <option
        key={0}
        value={value}>
        {value.length > 38 ? `${value.substring(0, 38)}...` : value}
      </option>
    </Select>
  );
};

export interface TicketFormProps {
  service: InventoryAsset;
  onSubmit: (formData: FormData) => Promise<void> | void;
  onError?: () => void;
  onChange?: (formState: FormState<FormData>) => void;
  additionalRecipients: string[];
  setAdditionalRecipients: (newVal: string[]) => void;
  saveDefault: boolean;
  setSaveDefault: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const TicketForm = ({
  service,
  onSubmit,
  onError,
  onChange,
  additionalRecipients,
  setAdditionalRecipients,
  saveDefault,
  setSaveDefault
}: TicketFormProps) => {
  const [isServiceDown, setIsServiceDown] = useState<boolean | null>(null);
  const [serviceSubject, setServiceSubject] = useState<string>('');
  const { register, handleSubmit, formState, reset, setValue } = useForm<FormData>();
  const [additionalRecipInput, setAdditionalRecipInput] = useState<string>();
  const { errors } = formState;

  useEffect(() => {
    fetchSharedAccountCustomizations();
  }, []);

  const handleResetFormState = () => {
    reset({}, { keepIsValid: false, keepErrors: true, keepDirty: true, keepValues: true });
    if (onError) onError();
  };

  const onAdditionalRecipientsDelete = (email: string) => () => {
    const update = [...additionalRecipients].filter((v) => v !== email);
    setAdditionalRecipients(update);
  };

  const handleAdd = () => {
    if (additionalRecipInput && emailRegex.test(additionalRecipInput)) {
      setAdditionalRecipients([...(additionalRecipients || []), additionalRecipInput]);
      setAdditionalRecipInput('');
    }
  };

  const handleServiceStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsServiceDown(event.target.value === 'true');
    setValue('isServiceDown', event.target.value);
  };

  const handleServiceSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceSubject(event.target.value);
    setValue('serviceSubject', event.target.value);
  };

  const serviceRadioGroup = () => {
    return (
      <FormControl sx={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
        <FormLabel id="service-radio-buttons-group">
          <TextSpan size="sm14">Is service out or impaired?</TextSpan>
        </FormLabel>
        <RadioGroup
          sx={{
            marginLeft: '.5rem'
          }}
          row
          aria-labelledby="service-radio-buttons-group"
          value={isServiceDown}
          onChange={handleServiceStatusChange}>
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const requestTypeRadioGroup = () => {
    return (
      <FormControl>
        <FormLabel id="request-radio-buttons-group">
          <TextSpan size="sm14">Request Type</TextSpan>
        </FormLabel>
        <RadioGroup
          sx={{
            marginLeft: '1rem'
          }}
          row
          aria-labelledby="request-radio-buttons-group"
          value={serviceSubject}
          onChange={handleServiceSubjectChange}>
          <FormControlLabel
            value="Disconnect This Service"
            control={<Radio />}
            label="Disconnect This Service"
          />
          <FormControlLabel
            value="Billing or Administrative Issues"
            control={<Radio />}
            label="Billing or Administrative Issues"
          />
          <FormControlLabel
            value="Other"
            control={<Radio />}
            label="Other (RFO/RCA, Supplier Inquiry, etc.)"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <>
      <form
        data-testid="ticket-creation-form"
        onSubmit={handleSubmit(onSubmit, handleResetFormState)}
        onChange={() => onChange && onChange(formState)}
        id="ticket-creation-form">
        <div className="flex my-2 w-[20rem] m-auto">{addressSelect(service)}</div>
        <div className="flex mt-2 w-[20rem] m-auto">{serviceRadioGroup()}</div>
        {isServiceDown === false && <div className="flex my-2 w-[19rem] m-auto">{requestTypeRadioGroup()}</div>}
        {((isServiceDown === false && serviceSubject !== '') || isServiceDown) && (
          <>
            {isServiceDown && (
              <div className="relative after:content-['Optional'] after:absolute after:right-12 after:top-2.5 after:text-gray-400 after:text-sm">
                <input
                  type="text"
                  className={`${SELECT_INPUT_STYLE}`}
                  id="supplierTicketNumber"
                  data-cy="issue-supplier-ticket-number"
                  aria-label="Supplier Ticket Number"
                  placeholder="Supplier Ticket #"
                  {...register('supplierTicketNumber', { required: false })}
                />
              </div>
            )}
            <textarea
              className={`${SELECT_INPUT_STYLE} ${errors.Description && 'border border-[red]'}`}
              data-cy="issue-details"
              id="description"
              {...register('Description', {
                required: true,
                maxLength: 2000,
                validate: validateDescription
              })}
              rows={5}
              placeholder="Details"
            />
            {errors.Description && (
              <div className="invalid-feedback">{validateDescription(errors.Description.type)}</div>
            )}
            <AdditionalRecipients
              additionalRecipients={additionalRecipients}
              handleAdd={handleAdd}
              additionalRecipInput={additionalRecipInput}
              setAdditionalRecipInput={setAdditionalRecipInput}
              saveDefault={saveDefault}
              setSaveDefault={setSaveDefault}
              onAdditionalRecipientsDelete={onAdditionalRecipientsDelete}
              showDefaultBox={true}
              styles={{
                divClassName: 'space-y-3',
                textField: {
                  width: '20rem',
                  margin: 'auto',
                  display: 'flex'
                },
                box: {
                  '& > :not(:last-child)': { marginRight: 1 },
                  '& > *': { marginBottom: 1 },
                  width: '20.5rem',
                  marginLeft: 'auto',
                  maxHeight: '8rem',
                  overflow: 'auto'
                },
                chip: {
                  marginBottom: '.5rem',
                  borderRadius: '5px'
                },
                formControlLabel: {
                  display: 'flex',
                  margin: 'auto',
                  width: '20rem'
                }
              }}
            />
            {infoText()}
          </>
        )}
      </form>
    </>
  );
};
