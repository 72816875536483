import { useState, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { fetchPortalUsers } from '../Api/Admin/PortalUsers/fetchPortalUsers';
import { PortalUserData, UserRoles } from '../types';
import { deletePortalUserApi } from '../Api/Admin/PortalUsers/deletePortalUserApi';
import { putPortalUser } from '../Api/Admin/PortalUsers/putPortalUser';
import { useSnackbar } from '../Context/SnackbarContext';
import { addError } from './Error';
import { PostPortalUserRequest, postPortalUser } from '../Api/Admin/PortalUsers/postPortalUser';
import { postClientMail } from '../Api/Admin/PortalUsers/postClientMail';
import { FormStateStatus, getFormCreateStateSubject, getFormsSubject } from './BulkForm';
import { FormSubmitState, getFormSubject, getFormSubmitStateSubject } from './AddPortalUsersToTeamFormState';
import { useRoles } from './Role';
import { AddUserToTeamPayload, addUserToTeamApi } from '../Api/Admin/PortalUsers/portalUsersApi';
import { getAccountByIdOrSfId, getAccountName } from './Accounts';
import { UserPreferences } from './User';
import { createSettingsApi } from '../Api/User/CreateUserSettingsApi';
import { fetchMyTeam } from '../Api/MyTeam/fetchMyTeam';

export interface UserFormProps extends PostPortalUserRequest {
  dirty: boolean;
  valid: boolean;
  userCustomizations: UserPreferences;
  id: string;
  ui_element: React.ReactElement;
}

export interface UsePortalUsersReturn {
  setPortalUsersData: (portalUsers: PortalUserData[]) => void;
  clear: () => void;
  portalUsers: PortalUserData[];
  getPortalUsers: () => Promise<void>;
  createUsers: () => Promise<PromiseSettledResult<void>[]>;
  updateUser: () => Promise<void>;
  deletePortalUser: (portalUserId: string) => Promise<boolean | undefined>;
  createRequest: (requestBody: PostPortalUserRequest, userCustomizations: UserPreferences) => Promise<void>;
  addUserToTeam: (requestBody: AddUserToTeamPayload | PortalUserData, asManager: boolean) => Promise<void>;
  addUsersToTeam: () => Promise<void | undefined>;
  getMentionableUsers: (accountId: string) => Promise<PortalUserData[]>;
}

export const defaultPortalUserData: PortalUserData[] = [];

const emitErrorState = (message?: string): void => addError('portal-user', message);

const portalUserSubject = new BehaviorSubject<PortalUserData[]>(defaultPortalUserData);
const portalUserUpdateSubject = new BehaviorSubject<PortalUserData | undefined>(undefined);
const portalUsersLoadingSubject = new BehaviorSubject<boolean>(false);

// Getters
export const getPortalUserSubject = (): BehaviorSubject<PortalUserData[]> => portalUserSubject;
export const getPortalUserUpdateSubject = (): BehaviorSubject<PortalUserData | undefined> => portalUserUpdateSubject;
export const getPortalUsers = (): PortalUserData[] => portalUserSubject.value;
export const getPortalUsersLoadingSubject = (): BehaviorSubject<boolean> => portalUsersLoadingSubject;
export const getUsersByAccount = (id: string) => {
  return portalUserSubject.getValue().filter((u) => u.account_id === id);
};

// Actions
export const updatePortalUser = (userData: PortalUserData) => portalUserUpdateSubject.next(userData);
export const deletePortalUser = async (portalUserId: string): Promise<boolean | undefined> => {
  const { error } = await deletePortalUserApi(portalUserId);

  if (error) return false;

  const updatedPortalUsers = getPortalUsers().filter((portalUser) => portalUser.id !== portalUserId);
  portalUserSubject.next(updatedPortalUsers);
  return true;
};

export const usePortalUsers = (): UsePortalUsersReturn => {
  const [portalUsers, setPortalUsers] = useState(defaultPortalUserData);
  const [userDataUpdate, setUserDataUpdate] = useState<PortalUserData>();
  const { roles } = useRoles();
  const { setSnack } = useSnackbar();

  useEffect(() => {
    const userSubscription = portalUserSubject.subscribe((portalUsers) => setPortalUsers(portalUsers));
    const updateSubscription = getPortalUserUpdateSubject().subscribe((userData) => setUserDataUpdate(userData));

    return () => {
      if (userSubscription) userSubscription.unsubscribe();
      if (updateSubscription) updateSubscription.unsubscribe();
    };
  }, []);

  const getPortalUsers = async () => {
    if (portalUsersLoadingSubject.getValue()) return;

    portalUsersLoadingSubject.next(true);
    const { data, error } = await fetchPortalUsers();

    if (data) {
      emitErrorState();
      const formattedUsers = data.map((user: PortalUserData) => ({
        ...user,
        rolesDisplay: user?.roles?.length > 0 ? user.roles.map((role: UserRoles) => role?.name).join(', ') : ''
      }));
      setPortalUsersData(formattedUsers);
    }

    if (error) {
      emitErrorState(error.message);
    }

    portalUsersLoadingSubject.next(false);
  };

  const updateUser = async () => {
    if (!userDataUpdate) return;
    // get account sf id, needed for contact creation/update
    const matchingAccount = getAccountByIdOrSfId(userDataUpdate.account_id || '');
    userDataUpdate.account_id = matchingAccount?.sfId;

    const { error, data } = await putPortalUser(userDataUpdate);

    if (data) {
      // Add account name if missing.
      if (data.account_id && !data.account_name) data.account_name = getAccountName(data.account_id);
      data.accounts.forEach((acc) => {
        acc.name = acc.name || getAccountName(acc.id) || '';
      });

      const updatedUsers = portalUsers.map((portalUser) =>
        portalUser.id === data.id
          ? { ...data, rolesDisplay: data.roles?.map((role) => role?.name).join(', ') || '' }
          : portalUser
      );

      portalUserSubject.next(updatedUsers);
      portalUserUpdateSubject.next(data);
      emitErrorState();
      setSnack({ message: 'User data saved successfully.', type: 'success', open: true });
    }

    if (error) {
      emitErrorState(error.message);
      setSnack({
        type: 'error',
        message: 'Failed to update user.',
        open: true
      });
    }

    portalUsersLoadingSubject.next(false);
  };

  const createRequest = async (requestBody: PostPortalUserRequest, userCustomizations: UserPreferences) => {
    const { data, error } = await postPortalUser(requestBody);
    const currentState = [...getFormCreateStateSubject().getValue()];

    if (error) {
      const update = {
        status: FormStateStatus.FAILED,
        data: { userCustomizations } as Partial<PortalUserData>,
        error: error.message,
        requestBody
      };
      const errorIndex = currentState.findIndex(
        (i) => (i.requestBody as PostPortalUserRequest).email === requestBody.email
      );

      if (errorIndex > -1) {
        currentState[errorIndex] = update;
        getFormCreateStateSubject().next([...currentState]);
      } else {
        getFormCreateStateSubject().next([...currentState, update]);
      }
    }

    if (data) {
      getFormCreateStateSubject().next([
        ...currentState,
        { status: FormStateStatus.SUCCESS, data, error: undefined, requestBody }
      ]);

      await createSettingsApi({ ...userCustomizations, portal_user_id: Number(data.id) });
      await postClientMail({ userId: data.id });
    }
  };

  const createUsers = async () => {
    const forms = getFormsSubject().getValue();
    const promises: Promise<void>[] = forms.map((form) => {
      const { first_name, last_name, email, roles_attributes, account_id, team_id, userCustomizations, accounts } =
        form as UserFormProps;

      const requestBody = { first_name, last_name, email, roles_attributes, account_id, team_id, accounts };
      return createRequest(requestBody, userCustomizations);
    });

    return await Promise.allSettled(promises);
  };

  const addUserToTeam = async (requestBody: AddUserToTeamPayload | PortalUserData, asManager: boolean) => {
    if (asManager) return addManagerToTeam(requestBody as PortalUserData);
    return addMemberToTeam(requestBody as AddUserToTeamPayload);
  };

  const addMemberToTeam = async (requestBody: AddUserToTeamPayload) => {
    const { error, data } = await addUserToTeamApi(requestBody as AddUserToTeamPayload);
    handleUserAddedToTeam(data, error, requestBody);
  };

  const addManagerToTeam = async (requestBody: PortalUserData) => {
    const { data, error } = await putPortalUser(requestBody as PortalUserData);
    handleUserAddedToTeam(data, error, requestBody, true);
  };

  const handleUserAddedToTeam = (
    data: PortalUserData | null,
    error: { message: string } | null,
    requestBody: PortalUserData | AddUserToTeamPayload,
    manager: boolean = false
  ) => {
    const currentState = getFormSubmitStateSubject().getValue();

    const stateIndex = currentState.findIndex((i) => {
      if (manager) {
        return (i.requestBody as PortalUserData).id === (requestBody as PortalUserData).id;
      } else {
        return (i.requestBody as AddUserToTeamPayload).userId === (requestBody as AddUserToTeamPayload).userId;
      }
    });

    let update: FormSubmitState = currentState[stateIndex];

    if (error) {
      update = {
        status: FormStateStatus.FAILED,
        data: currentState[stateIndex].data,
        error: error.message,
        requestBody
      };
    }

    if (data) {
      update = { status: FormStateStatus.SUCCESS, data, error: undefined, requestBody };
      const updatedUsers = portalUsers.map((portalUser: PortalUserData) =>
        portalUser.id === data.id ? data : portalUser
      );

      setPortalUsersData(updatedUsers);
    }

    if (stateIndex > -1) {
      currentState[stateIndex] = update;
      getFormSubmitStateSubject().next([...currentState]);
    } else {
      getFormSubmitStateSubject().next([...currentState, update]);
    }
  };

  const addUsersToTeam = async () => {
    const form = getFormSubject().getValue();

    if (form.portal_users.length === 0) return;
    const teamManagerRole = roles.find((r) => r.name === 'Team Manager');

    const formSubmitState = form.portal_users.map((user: PortalUserData): FormSubmitState => {
      let userUpdate: AddUserToTeamPayload | PortalUserData;

      if (form.type === 'manager') {
        userUpdate = {
          ...user,
          team_id: Number(form.team_id),
          roles: [...user.roles, teamManagerRole as UserRoles]
        };
      } else {
        userUpdate = {
          userId: user.id,
          teamId: Number(form.team_id)
        };
      }

      const formState = {
        status: FormStateStatus.SUBMITTING,
        data: user,
        requestBody: userUpdate
      };

      return formState;
    });

    getFormSubmitStateSubject().next(formSubmitState);

    const promises = formSubmitState.map(async (formState) => {
      return addUserToTeam(formState.requestBody, form.type === 'manager');
    });

    await Promise.all(promises);
  };

  const getMentionableUsers = async (accountId: string) => {
    const mentionableUsers = [];
    const [customers, myTeam] = await Promise.all([
      fetchPortalUsers(accountId).then((res) => res.data),
      fetchMyTeam(accountId).then((res) => res.data)
    ]);

    if (customers) mentionableUsers.push(...customers);
    if (myTeam) {
      const { owner, success_manager } = myTeam;
      const formattedOwner = {
        id: owner.id,
        first_name: owner.firstName,
        last_name: owner.lastName,
        email: owner.email
      } as PortalUserData;
      const formattedSuccessManager = {
        id: success_manager.id,
        first_name: success_manager.firstName,
        last_name: success_manager.lastName,
        email: success_manager.email
      } as PortalUserData;
      mentionableUsers.push(formattedOwner, formattedSuccessManager);
    }
    return mentionableUsers;
  };

  const setPortalUsersData = (portalUsers: PortalUserData[]) => portalUserSubject.next(portalUsers);
  const clear = () => portalUserSubject.next(defaultPortalUserData);

  return {
    setPortalUsersData,
    clear,
    portalUsers,
    getPortalUsers,
    deletePortalUser,
    updateUser,
    createUsers,
    createRequest,
    addUserToTeam,
    addUsersToTeam,
    getMentionableUsers
  };
};
