import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import appConfigChannel from './sockets/appConfig';

export interface UseFeatureFlagsReturn {
  config: FeatureFlags;
  errorMessage: string | undefined;
}
export interface FeatureFlags {
  maintenance_mode: boolean;
  notifications: boolean;
  comments: boolean;
  my_views: boolean;
  portal_support: boolean;
  commissions: boolean;
}
export const defaultFeatureFlags: FeatureFlags = {
  maintenance_mode: false,
  notifications: true,
  comments: false,
  my_views: false,
  portal_support: false,
  commissions: true
};
const defaultErrorMessage = undefined;

const subject = new BehaviorSubject<FeatureFlags>(defaultFeatureFlags);
const errorSubject = new BehaviorSubject<string | undefined>(defaultErrorMessage);

export const getFeatureFlagErrorSubject = (): BehaviorSubject<string | undefined> => errorSubject;
export const getFeatureFlagSubject = (): BehaviorSubject<FeatureFlags> => subject;

export const useFeatureFlags = (): UseFeatureFlagsReturn => {
  const [config, setConfig] = useState<FeatureFlags>(defaultFeatureFlags);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(defaultErrorMessage);

  // Leaving these empty for now.
  const connectedHandler = () => {};
  const disconnectedHandler = () => {};
  const receivedHandler = (data: FeatureFlags) => {
    setConfigData({ ...defaultFeatureFlags, ...data });
  };
  const createSocket = () => {
    appConfigChannel({ connectedHandler, disconnectedHandler, receivedHandler });
  };

  useEffect(() => {
    createSocket();

    const subscription = subject.subscribe((data) => {
      setConfig(data);
    });
    const errorSubscription = errorSubject.subscribe((message) => {
      setErrorMessage(message);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
      if (errorSubscription) errorSubscription.unsubscribe();
    };
  }, []);

  const setConfigData = (configData: FeatureFlags) => subject.next(configData);

  return { config, errorMessage };
};
