import { Account } from '../../../types';
import { callExternalApi } from '../../callExternalApi';

export const searchAccounts = async (query: string) => {
  return await callExternalApi<Account[]>({
    url: `api/admin/search_accounts?query=${query}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
