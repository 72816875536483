import DocViewer, { DocViewerRenderers, IConfig } from '@cyntler/react-doc-viewer';
import { TextBlock } from './Text';
import { MapDocPreviewer } from './MapDocPreviewer';

export type DocumentPreview = {
  id: string;
  uri: string;
  fileName: string;
  fileType: string;
  isS3?: boolean;
};

export interface DocumentPreviewToolProps {
  documents: DocumentPreview[];
  fileType: string;
  styles?: Record<string, string>;
  className?: string;
  configOverride?: Partial<IConfig>;
}

const REACT_DOC_VIEWER_TYPES = ['jpg', 'tiff', 'txt', 'png', 'jpeg', 'gif', 'bmp', 'pdf', 'csv'];

const MAP_DOC_VIEWER_TYPES = ['kml', 'kmz'];

const defaultStyles = '[&_#image-renderer]:bg-none [&_#pdf-renderer]:overflow-y-auto overflow-hidden';

export const DocumentPreviewTool = ({
  className,
  configOverride,
  documents,
  fileType,
  styles
}: DocumentPreviewToolProps) => {
  if (REACT_DOC_VIEWER_TYPES.includes(fileType)) {
    const defaultConfig = {
      header: {
        disableFileName: true,
        disableHeader: true
      }
    };

    const config = { ...configOverride, ...defaultConfig };

    return (
      <DocViewer
        documents={documents}
        initialActiveDocument={documents[0]}
        pluginRenderers={DocViewerRenderers}
        className={className ? `${className} ${defaultStyles}` : defaultStyles}
        config={config}
        style={styles}
      />
    );
  }

  if (MAP_DOC_VIEWER_TYPES.includes(fileType)) {
    return <MapDocPreviewer file={documents[0]} />;
  }

  return (
    <div className="h-full w-full pt-5">
      <TextBlock className="text-xs text-center">&quot;.{fileType}&quot; files not supported for preview</TextBlock>
    </div>
  );
};
