import { DetailViewCard } from '../CardWithTitle';
import { useEffect, useState } from 'react';
import { Comment, getCommentLoadingSubject, getCommentSubject, useCommentsStore } from '../../../store/Comment';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { DetailsCommentsCTA } from '../DetailsCommentsCTA/DetailsCommentsCTA';
import { CommentItem } from './CommentItem';
import { useCommentChannel } from '../../../Api/sockets/commentChannel';
import { TextEditor } from './TextEditor';
import { PortalUserData } from '../../../types';
import { usePortalUsers } from '../../../store/PortalUser';
import { useSearchParams } from 'react-router-dom';
import { NotificationFocus } from '../Notifications/NotificationItem';
import { getRoute1Subject } from '../../../store/Route1';
import { getAccountIdBySfId } from '../../../store/Accounts';

export interface DetailsCommentsProps {
  id: string;
  entityType: string;
  entityAccountId: string;
}

export function DetailsComments({ id, entityType, entityAccountId }: DetailsCommentsProps) {
  const [search, setSearch] = useSearchParams();
  const focus = search.get('focus');

  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(focus !== NotificationFocus.COMMENT);
  const [commentToReply, setCommentToReply] = useState<Comment | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [mentionableUsers, setMentionableUsers] = useState<PortalUserData[]>([]);
  const { fetchComments, createComment } = useCommentsStore();
  const { getMentionableUsers } = usePortalUsers();

  useCommentChannel(id, entityType);

  useEffect(() => {
    const fetchMentionableUsers = async () => {
      const users = await getMentionableUsers(getAccountIdBySfId(entityAccountId));
      setMentionableUsers(users);
    };

    const commentSub = getCommentSubject().subscribe((comments) => setComments(comments));
    const commentLoadingSub = getCommentLoadingSubject().subscribe((loading) => setIsFetching(loading));
    /**
     * @TODO REMOVE AFTER ROUTE1 migration
     */
    const route1Sub = getRoute1Subject().subscribe((enabled) => enabled && fetchMentionableUsers());
    fetchMentionableUsers();

    return () => {
      if (commentSub) commentSub.unsubscribe();
      if (commentLoadingSub) commentLoadingSub.unsubscribe();
      if (route1Sub) route1Sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (focus === NotificationFocus.COMMENT && comments.length) {
      const commentId = search.get('commentId') || '';
      const element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        if (search.has('focus')) search.delete('focus');
        if (search.has('commentId')) search.delete('commentId');
        setSearch(search, { replace: true });
      }
    }
  }, [focus, comments]);

  useEffect(() => {
    if (id) fetchComments(id, entityType);
  }, [id]);

  const cardActions = () => {
    return (
      <DetailsCommentsCTA
        setIsFormOpen={() => {
          setIsFormOpen(true);
          setCollapsed(false);
        }}
      />
    );
  };

  const handleSend = async (commentText: string) => {
    setSending(true);
    await createComment({ id: '', entity_id: id, entity_type: entityType, comment_text: commentText });
    setIsFormOpen(false);
    setSending(false);
  };

  const handleCancel = () => {
    setIsFormOpen(false);
  };

  const sortComments = (comments: Comment[]) => {
    return comments.sort((a, b) => {
      return a?.created_at && b?.created_at ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime() : 1;
    });
  };

  const commentTextWithMention = () => {
    if (commentToReply?.parent_comment_id) {
      return (
        '<p><span class="mention" data-index="1" data-denotation-char="@" data-id="' +
        `${commentToReply.portal_user_email}" data-value="${commentToReply.portal_user_name}"` +
        `contenteditable="false">@${commentToReply.portal_user_name}</span>&nbsp;</p>`
      );
    } else {
      return '';
    }
  };

  const renderComments = () => {
    return sortComments(comments.filter((c) => !c.parent_comment_id)).map((comment) => (
      <>
        <CommentItem
          comment={comment}
          handleReply={handleReply}
          mentionableUsers={mentionableUsers}
        />
        {sortComments(comments.filter((r) => String(r.parent_comment_id) == comment.id))
          .reverse()
          .map((reply) => (
            <CommentItem
              comment={reply}
              handleReply={handleReply}
              key={reply.id}
              mentionableUsers={mentionableUsers}
            />
          ))}
        {isReplying && (commentToReply == comment || String(commentToReply?.parent_comment_id) == comment.id) && (
          <div className="mt-4 ml-6">
            <TextEditor
              commentText={commentTextWithMention()}
              handleCancel={handleCancelReply}
              handleSend={handleCreateReply}
              portalUsers={mentionableUsers}
              placeholder="Reply to this comment..."
            />
          </div>
        )}
      </>
    ));
  };

  const handleReply = (comment: Comment) => {
    setCommentToReply(comment);
    setIsReplying(true);
  };

  const handleCancelReply = () => {
    setCommentToReply(null);
    setIsReplying(false);
  };

  const handleCreateReply = (comment_text: string) => {
    if (commentToReply) {
      createComment({
        id: '',
        entity_id: commentToReply.entity_id,
        entity_type: commentToReply.entity_type,
        comment_text: comment_text,
        parent_comment_id: Number(commentToReply.parent_comment_id || commentToReply.id)
      });
      setCommentToReply(null);
    }
    setIsReplying(false);
  };

  return (
    <DetailViewCard
      cardActions={cardActions()}
      collapsed={collapsed}
      enableCollapse
      title={`Comments (${comments.filter((c) => !c.parent_comment_id)?.length || 0})`}
      childrenWrapperClass="overflow-x-auto">
      <div className="font-sans text-black">
        {(isFetching || sending) && <ComponentSpinner label={sending ? 'Processing' : 'Loading'} />}
        {!isFetching && !sending && isFormOpen && (
          <TextEditor
            commentText=""
            handleCancel={handleCancel}
            handleSend={handleSend}
            portalUsers={mentionableUsers}
            placeholder="Comment here"
          />
        )}
        {!isFetching && !sending && !comments?.length && !isFormOpen && <div className="text-center">No comments</div>}
        {!isFetching && !sending && !!comments?.length && renderComments()}
      </div>
    </DetailViewCard>
  );
}
