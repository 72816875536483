import { useEffect, useState } from 'react';
import { InventoryAsset, SupportTicketsItem, WidgetDef } from '../../../types';
import { UpcomingExpirationsWidget } from '../../Compounds/UpcomingExpirationsWidget';
import { SuppliersWidget } from '../../Compounds/SuppliersWidget';
import { StatusWidget } from '../../Compounds/StatusWidget';
import { TicketsWidget } from '../../Compounds/TicketsWidget';
import { MapWidget } from '../../Compounds/MapWidget';
import Masonry from 'react-responsive-masonry';
import {
  WidgetKey,
  WidgetPreferences,
  getDefaultPreferences,
  getUser,
  getUserPreferencesSubject,
  isVisibleWidgetByPermissions
} from '../../../store/User';
import { ComponentSpinner } from '../Loading/ComponentSpinner';

export interface DashboardWidgetsProps {
  inventoryAssets: InventoryAsset[] | [];
  pageTitle: string;
  tickets: SupportTicketsItem[] | [];
}

const widgetDefsMap = (page: WidgetKey) => {
  const map = getDefaultPreferences().content.widgets;
  return map[page];
};

export const DashboardWidgets = ({ inventoryAssets, pageTitle, tickets }: DashboardWidgetsProps) => {
  const [colCount, setColCount] = useState(3);
  const [chartWidth, setChartWidth] = useState(window.innerWidth);
  const lowerTitle = pageTitle.toLocaleLowerCase() as WidgetKey;
  const [widgetSettings, setWidgetSettings] = useState<WidgetPreferences[]>();
  const [widgetDefs, setWidgetDefs] = useState<WidgetDef[]>();
  const isHome = pageTitle === 'Home';
  const masonryItemClass = isHome ? 'masonry-item' : '';
  const masonryStyle = isHome ? { width: '100%', display: 'block' } : undefined;

  const matchedSetting = (widgetId: string): WidgetPreferences | undefined => {
    return widgetSettings?.find((widget) => widget.widgetId === widgetId);
  };

  useEffect(() => {
    function handlePageResize() {
      const width = window.innerWidth;

      if (width >= 1200) {
        setColCount(3);
        setChartWidth(Number(`${(width - 255) / 3}`));
      } else if (width >= 765) {
        setColCount(2);
        setChartWidth(Number(`${(width - 235) / 2}`));
      } else {
        setColCount(1);
        setChartWidth(Number(`${width - 215}`));
      }
    }
    window.addEventListener('resize', handlePageResize);

    handlePageResize();

    const settingsSub = getUserPreferencesSubject().subscribe((w) => {
      const widgetPrefs = w?.content?.widgets;
      if (widgetPrefs) {
        const widgetPagePrefs = widgetPrefs[lowerTitle];
        setWidgetDefs(widgetDefsMap(lowerTitle));
        setWidgetSettings(widgetPagePrefs);
      }
    });

    return () => {
      window.removeEventListener('resize', handlePageResize);
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  const widgetLists = () => {
    const widgetComponents = {
      upcomingExpirations: (
        <UpcomingExpirationsWidget
          inventoryAssets={inventoryAssets}
          chartWidth={chartWidth}
          isHome={isHome}
        />
      ),
      status: (
        <StatusWidget
          inventoryAssets={inventoryAssets}
          isHome={isHome}
        />
      ),
      locations: (
        <MapWidget
          inventoryAssets={inventoryAssets}
          isHome={isHome}
        />
      ),
      suppliers: (
        <SuppliersWidget
          inventoryAssets={inventoryAssets}
          isHome={isHome}
        />
      ),
      tickets: (
        <TicketsWidget
          tickets={tickets}
          barWidth={chartWidth}
          isHome={isHome}
        />
      )
    };

    return widgetDefs && widgetSettings ? (
      widgetDefs
        .map((def) => matchedSetting(def.widgetId) || def)
        .sort((a, b) => a.index - b.index)
        .filter((widget) => {
          return !widget.disabled && !widget.hide && isVisibleWidgetByPermissions(widget.widgetId, getUser());
        })
        .map((widget) => (
          <div
            className={masonryItemClass}
            style={masonryStyle}
            key={widget.widgetId}>
            {widgetComponents[widget.widgetId]}
          </div>
        ))
    ) : (
      <div className="m-32">
        <ComponentSpinner />
      </div>
    );
  };

  return (
    <>
      {isHome && (
        <Masonry
          columnsCount={colCount}
          gutter="20px">
          {widgetLists()}
        </Masonry>
      )}
      {!isHome && <div className="flex flex-wrap gap-4">{widgetLists()}</div>}
    </>
  );
};
